import "hammerjs";
import { enableProdMode } from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";
import * as Sentry from "@sentry/angular";

import { AppModule } from "./app/app.module";
import { environment } from "./environments/environment";
import packageJson from "package.json";
import { serverErrorsRegex } from "./app/utils";

if (environment.production) {
  enableProdMode();
  if (window) {
    window.console.log = () => {};
  }
}

Sentry.init({
  dsn: environment.sentryDsn,
  release: packageJson.version,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  environment: environment.production ? "production" : "sandbox",
  // Performance Monitoring
  tracesSampleRate: 0.2, //  Capture 20% of the transactions
  ignoreErrors: [serverErrorsRegex],
});

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
