import { Component, OnInit, ViewChild } from "@angular/core";
import { environment } from "../environments/environment";
import { LoginService } from "./services/login/login.service";
import { Router } from "@angular/router";
import { MatSidenav } from "@angular/material/sidenav";
import { DataSharingService } from "src/app/services/data-sharing.service";
import { MatDialog } from "@angular/material/dialog";
import { SbicLoginComponent } from "src/app/shared/components/sbic-login/sbic-login.component";
import { appConstants } from "./app.constants";
@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
  isAuthenticated = this.login.isAuthenticated();
  hidePageBottom: boolean = false;

  constructor(
    public login: LoginService,
    public router: Router,
    public data: DataSharingService,
    public dialog: MatDialog,
  ) {
    this.addScript(
      `https://maps.googleapis.com/maps/api/js?key=${environment.gmApiKey}`
    );
    this.deregisterServiceWorker();
  }
  env = environment;
  appSideNavOpen: boolean = false;
  accountSideNavOpen: boolean = true;

  @ViewChild("sidenav", { static: false }) usuarioMenu: MatSidenav;

  title = "rewards-hub";
  shouldRun = [/(^|\.)plnkr\.co$/, /(^|\.)stackblitz\.io$/].some((h) =>
    h.test(window.location.host)
  );
  apps = appConstants.apps;
  ngOnInit() {
    if (window.location.pathname.split("/")[1]) {
      this.hidePageBottom = true;
    }
    // if (this.login.isAuthenticated()) {
    // this.login
    //   .sbiValidate()
    //   .toPromise()
    //   .then((result: any) => {
    //     if (!(result && result.id)) {
    //       // this.login.logout();
    //       localStorage.removeItem("token");
    //     }
    //   });
    // }
  }

  headerUpdate(value) {
    if (value) this.isAuthenticated = true;
    else this.isAuthenticated = false;
  }

  closeAllSidenav() {
    this.usuarioMenu.close();
  }

  toggleAppSideNavOpen() {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    this.appSideNavOpen = !this.appSideNavOpen;
  }

  toggleAccountSideNavOpen() {
    this.accountSideNavOpen = !this.accountSideNavOpen;
  }

  onAppChange(url) {
    if (url) {
      this.router.navigateByUrl(url);
      this.closeAllSidenav();
    }
    // switch (appName) {
    //   case 'Natgeo':
    //     this.router.navigateByUrl('/nat-geo/home')
    //     break;
    //   case 'Hotels':
    //     this.router.navigateByUrl('/hotels')
    //     break;
    //   case 'Flights':
    //     this.router.navigateByUrl('/flights')
    //     break;
    //   case 'Gift Cards':
    //     this.router.navigateByUrl('/giftcards')
    //     break;
    //   case 'Activities & Experiences':
    //     this.router.navigateByUrl('/tours-and-activities')
    //     break;
    //   case 'Charitable Donations':
    //     this.router.navigateByUrl('/charitable-donations')
    //     break;

    // }
  }

  openDialog(): void {
    this.closeAllSidenav();
    this.dialog.open(SbicLoginComponent, {
      width: "450px",
      height: "70vh",
      disableClose: true,
    });
  }

  openBookingHistory() {
    this.closeAllSidenav();
    this.router.navigate(["booking/home"]);
  }

  logout() {
    this.closeAllSidenav();
    this.login.logout();
  }
  navigateToHome() {
    this.router.navigateByUrl("/");
  }

  openMyAccount() {
    this.closeAllSidenav();
    this.router.navigate(["myaccount"]);
  }
  addScript(linkurl) {
    var headID = document.getElementsByTagName("head")[0];
    var link = document.createElement("script");
    link.src = linkurl;
    link.async = true;
    headID.appendChild(link);
  }

  private deregisterServiceWorker() {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker
        .getRegistrations()
        .then((registrations) => {
          console.log('registrations to unregister', registrations)
          for (const registration of registrations) {
            registration.unregister()
          }
        })
        .catch(function (err) {
          console.log('Service Worker registration failed: ', err)
        })
    }
  }
}
