import { Injectable } from "@angular/core";
import { ApiService } from "../common/api/api.service";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { Router, ActivatedRoute } from "@angular/router";
import { tap, map, catchError } from "rxjs/operators";
import { throwError } from 'rxjs';

@Injectable({
  providedIn: "root",
})
export class LoginService {
  // url = environment.stagingUrl;
  url = environment.v2ApiUrl;

  httpOptions = {
    headers: new HttpHeaders({
      "X-API-Client": "sbic",
      "x-client-id": environment.clientId,
      authorization: `Bearer ${
        JSON.parse(localStorage.getItem("currentUser"))
          ? JSON.parse(localStorage.getItem("currentUser")).token
          : ""
      }`,
      "Content-Type": "application/json",
      Accept: "*/*",
    }),
  };

  logoutProgress: boolean;

  // a=

  // const options = { params: new HttpParams(httpParams), headers: headers };

  constructor(
    private api: ApiService,
    public http: HttpClient,
    public router: Router,
    public route: ActivatedRoute
  ) {}

  requestUserOtp(userId, mobile) {
    let body = { mobile: mobile, otp_type: "login" };
    return this.http.post(
      this.url + `/users/${userId}/otps`, // `users/otp_login?login=${mobile}&otp=${otp}`,
      body,
      this.httpOptions
    );
  }

  sbiValidate(redirect?) {
    let body
    console.log(this.route.snapshot.queryParamMap.get("token"))
    if(this.route.snapshot.queryParamMap.get("token")){
      redirect = true;
      localStorage.setItem(
        "token",
        this.route.snapshot.queryParamMap.get("token")
      );
    }
    if (!redirect) {
      body = {
        token: localStorage.getItem("token")
          ? localStorage.getItem("token")
          : "",
      };
    } else {
      body = {
        token: localStorage.getItem("token")
          ? localStorage.getItem("token")
          : "",
        redirect: true,
        SOURCEID: this.route.snapshot.queryParamMap.get("SOURCEID")
      };
    }
    // 
    return this.http.post(
      this.url + `/sessions/validate`, // `users/otp_login?login=${mobile}&otp=${otp}`,
      body,
      (this.httpOptions = {
        headers: new HttpHeaders({
          "X-API-Client": "sbic",
          "x-client-id": environment.clientId,
          authorization: `Bearer ${
            JSON.parse(localStorage.getItem("currentUser"))
              ? JSON.parse(localStorage.getItem("currentUser")).token
              : ""
          }`,
          "Content-Type": "application/json",
          Accept: "*/*",
        }),
      })
    );
  }

  generateOTP(mobile) {
    return this.http.get(
      this.url + `users/generate_otp?login=${mobile}`,
      this.httpOptions
    );
  }

  validateExistingUser(mobile) {
    return this.http.get(
      this.url + `/users/search?mobile=${mobile}`,
      this.httpOptions
    );
  }

  deleteSession() {
    return this.http
      .post(this.url + `/sessions/destroy`, this.httpOptions)
      .toPromise();
  }

  logout() {
    this.logoutProgress = true;
    let storage = localStorage;

    if (storage.getItem("token")) {
      localStorage.clear();
      // localStorage.removeItem("token");
      // localStorage.removeItem("auth_token");
      this.logoutProgress = false;

      // this.deleteSession().then( res =>{
      //   this.logoutProgress = false;

      // })
      // this.sbiValidate()
      //   .toPromise()
      //   .then((result:any) => {
      //     if(!result.success){
      //       localStorage.removeItem("token");
      //       localStorage.removeItem("auth_token");
      //       this.logoutProgress = false;
      //     }else{
      //       // this.http
      //       // .delete(this.url + "auth/sign_out")
      //       // .toPromise()
      //       // .then(() => {
      //       //   localStorage.removeItem("token");
      //       //   this.logoutProgress = false;
      //       //   this.router.navigate(["/"]);
      //       // });
      //       localStorage.removeItem("token");
      //       localStorage.removeItem("auth_token");
      //       this.logoutProgress = false;
      //     }
      //   });
    } else {
      this.logoutProgress = false;
    }
  }

  createUser(email, mobile) {
    let body = {
      email: email,
      mobile: mobile,
      country_id: "fdbbc206-9de1-4769-b6cf-eded1043547d",
    };
    return this.http
      .post(this.url + `/users`, body, this.httpOptions)
      .toPromise();
  }

  firstTimeOtpUser(email, mobile) {
    let body = { email: email, mobile: mobile };
    return this.http
      .post(this.url + `users/first_time_otp`, body, this.httpOptions)
      .toPromise();
  }

  registerUser(email, mobile, otp) {
    let body = { email: email, mobile: mobile, otp: otp };
    return this.http
      .post(this.url + `users/register_user`, body, {
        headers: new HttpHeaders({
          "X-API-Client": "sbic",
          "Content-Type": "application/json",
          Accept: "*/*",
        }),
        observe: "response",
      })
      .toPromise();
  }

  validateUserOtp(userId, otpType, otp) {
    let body = { otp_type: otpType, code: otp };
    return this.http.post(
      this.url + `/users/${userId}/otps/verify`, // `users/otp_login?login=${mobile}&otp=${otp}`,
      body,
      this.httpOptions
    );
  }

  // loginUser()

  isAuthenticated() {
    // let storage = ;
    return Boolean(localStorage.getItem("auth_token"));
  }

  getUserDetails() {
    // return this.http.get(this.url + `users/user_validation`);
    return this.http
      .get(this.url + `/user?fetch_points=true`)
      .pipe(
        map(res => res || res),
				catchError(error => throwError(error)),
        tap((user) =>
          localStorage.setItem("login_details", JSON.stringify(user))
        )
      );
  }

  checkUser(phone) {
    return this.http.get(
      this.url + `/users/search?mobile=${phone}`,
      this.httpOptions
    );
    // return this.http.get(this.url + `user/check_user?login=${phone}`);
  }

  getToken() {
    return this.http.post(this.url + `/sessions/initiate`, this.httpOptions);
  }

  redirectBack(token){
    let body = {token: token};
    return this.http.post(this.url + `/sessions/redirect_back`,body, this.httpOptions);
  }
}
